<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('FacilityProfile') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col
                                        class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="200">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <ValidationProvider
                                                    :name="$t('Form.Name')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        v-model="form.name"
                                                        :disabled="isDisabled"
                                                        :label="$t('Form.Name')"
                                                        required
                                                        filled
                                                        hide-details="auto"
                                                        name="name"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <ValidationProvider
                                                    :name="$t('Form.PostalCode')"
                                                    rules="required|postal-code"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        @change="getAddresses()"
                                                        :error="errors[0] ? true : false"
                                                        hide-details="auto"
                                                        v-model="form.zipCode"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        :label="$t('Form.PostalCode')"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="9">
                                                <ValidationProvider
                                                    :name="$t('Form.Address')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-combobox
                                                        :items="addresses"
                                                        item-value="Artéria"
                                                        item-text="Artéria"
                                                        v-model="form.address"
                                                        hide-details
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        hide-no-data
                                                        :label="$t('Form.Address')"
                                                        required></v-combobox>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        class="form-pic d-none d-md-flex d-lg-flex d-xl-flex justify-end"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="150">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Country')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getDistricts"
                                                v-model="selectedCountry"
                                                item-value="id"
                                                item-text="name"
                                                :items="countries"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                name="country"
                                                :label="$t('Country')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('District')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getCounties"
                                                :items="districts"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedDistrict"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('District')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('County')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getParishes"
                                                :items="counties"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedCounty"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('County')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Parish')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :items="parishes"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedParish"
                                                @change="getParishCoords"
                                                :disabled="isDisabled"
                                                filled
                                                :label="$t('Parish')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <ValidationProvider
                                            :name="$t('Form.LatLng')"
                                            rules="required|lat-lgn"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                v-model="coordinatesField"
                                                filled
                                                shaped
                                                :label="$t('Form.LatLng')"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <ValidationProvider
                                            :name="$t('Form.Length')"
                                            rules="required|min_value:0"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :min="0"
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                type="number"
                                                v-model="form.length"
                                                :label="$t('Form.Length')"
                                                suffix="m"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <ValidationProvider
                                            :name="$t('Form.Width')"
                                            rules="required|min_value:0"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :min="0"
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                type="number"
                                                v-model="form.width"
                                                :label="$t('Form.Width')"
                                                suffix="m"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="" cols="12" sm="12">
                                        <ValidationProvider :name="$t('Form.Keywords')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                v-model="form.keywords"
                                                :disabled="isDisabled"
                                                :label="$t('Form.Keywords')"
                                                filled
                                                hide-details="auto"
                                                name="name"
                                                shaped></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-row>
                                            <v-col class="d-flex" cols="12" sm="3">
                                                <ValidationProvider
                                                    :name="$t('IsWheelchairAccessible')"
                                                    v-slot="{ errors }">
                                                    <v-checkbox
                                                        v-model="form.isWheelchairAccessible"
                                                        :label="$t('IsWheelchairAccessible')"
                                                        :value="true"
                                                        hide-details
                                                        :disabled="isDisabled"></v-checkbox>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="d-flex" cols="12" sm="3">
                                                <ValidationProvider :name="$t('IsBlindAccessible')" v-slot="{ errors }">
                                                    <v-checkbox
                                                        v-model="form.isBlindAccessible"
                                                        :label="$t('IsBlindAccessible')"
                                                        :value="true"
                                                        hide-details
                                                        :disabled="isDisabled"></v-checkbox>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="d-flex" cols="12" sm="3">
                                                <ValidationProvider :name="$t('IsDeafAccessible')" v-slot="{ errors }">
                                                    <v-checkbox
                                                        v-model="form.isDeafAccessible"
                                                        :label="$t('IsDeafAccessible')"
                                                        :value="true"
                                                        hide-details
                                                        :disabled="isDisabled"></v-checkbox>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <GmapMap
                                            :center="place.coordinates"
                                            :zoom="16"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 500px">
                                            <GmapMarker
                                                :position="place.coordinates"
                                                :clickable="true"
                                                :draggable="false" />
                                        </GmapMap>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addFacility">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('FacilityProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('FacilityProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddFacility') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddFacility') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addFacility">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteFacility') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteFacility" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR ENTIDADES-->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddFacilitySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateFacilitySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteFacilitySuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import useFacilityList from '../facility-list/useFacilityList';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import { getLocations } from '@/api/location';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getAddress } from '@/api/address';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            address: '',
            zipCode: '',
            pictures: [],
            phone: '',
            facility: null,
            longitude: 0,
            latitude: 0,
            length: 0,
            width: 0,
            isWheelchairAccessible: false,
            isBlindAccessible: false,
            isDeafAccessible: false,
        },

        isLoadingTable: true,
        structure: [],
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            Map,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                //   costRules: [(v) => !!v || 'Cost is required', (v) => (v && v <= 0) || 'Não pode ser menor que 0'],
                emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],

                file: null,
                countries: [],
                districts: [],
                counties: [],
                parishes: [],
                selectedCountry: null,
                selectedDistrict: null,
                selectedCounty: null,
                selectedParish: null,
                selectedParishLat: null,
                selectedParishLng: null,
                addFacility: false,
                dialog: false,
                place: {
                    coordinates: {
                        lat: 0,
                        lng: 0,
                    },
                },
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                coordinatesField: null,
                addresses: [],
                selectedFacilityType: null,
            };
        },
        async created() {
            if (this.$route.params.id != 'add') {
                this.addFacility = false;
                this.isDisabled = true;
                await this.getFacility();
            } else {
                this.addFacility = true;
                this.isDisabled = false;
            }
            await this.getCountries();
        },

        setup() {
            const { resolveFacilityPictureMax, limitFacilityText } = useFacilityList();

            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                resolveFacilityPictureMax,
                avatarText,
                limitFacilityText,
            };
        },
        computed: {
            getPic() {
                return this.resolveFacilityPictureMax(this.form.pictures);
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            //get facility info
            getFacility() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-facility/fetchFacility', this.$route.params.id)
                    .then((response) => {
                        this.form = response.data.data;
                        this.selectedCountry = _.get(this.form, 'location.parent.parent.parent.id');
                        this.selectedDistrict = _.get(this.form, 'location.parent.parent.id');
                        this.selectedCounty = _.get(this.form, 'location.parent.id');
                        this.selectedParish = _.get(this.form, 'location.id');
                        this.getDistricts();
                        this.getCounties();
                        this.getParishes();
                        this.getAddresses();
                        this.coordinatesField = this.form.latitude + ', ' + this.form.longitude;
                        this.place.coordinates.lat = this.form.latitude;
                        this.place.coordinates.lng = this.form.longitude;
                        this.selectedFacilityType = this.form.type;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onSubmit() {
                if (this.addFacility) {
                    await this.onAddFacility();
                } else await this.onUpdateFacility();

                setTimeout(() => {
                    this.$router.push({ name: 'FacilityList' });
                }, 1500);
            },

            //add facility
            async onAddFacility() {
                this.isLoading = true;

                let facilityData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto facilityData (colonar objeto) é o que faz a função assing do lodash

                facilityData.latitude = this.coordinatesField.split(',')[0];
                facilityData.longitude = this.coordinatesField.split(',')[1];
                facilityData.location = this.selectedParish;

                await this.$store
                    .dispatch('app-facility/addFacility', facilityData)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })

                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            //update facility
            async onUpdateFacility() {
                this.isLoading = true;
                let facilityData = _.assign({}, this.form);
                facilityData.latitude = this.coordinatesField.split(',')[0];
                facilityData.longitude = this.coordinatesField.split(',')[1];
                facilityData.location = this.selectedParish;
                //update facility
                await this.$store
                    .dispatch('app-facility/updateFacility', facilityData)

                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onFileInput() {
                this.isLoading = true;
                await uploadFile(this.file).then((response) => {
                    this.form.pictures = [response.data[0]];
                });
                this.isLoading = false;
            },
            async getCountries() {
                await getLocations({ filters: { level: 0 } }).then((response) => {
                    this.countries = response.data.data;
                });
            },
            getDistricts() {
                this.districts = [];
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedCountry } }).then((response) => {
                    this.districts = response.data.data;
                });
            },
            getCounties() {
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedDistrict } }).then((response) => {
                    this.counties = response.data.data;
                });
            },
            getParishes() {
                getLocations({ filters: { parent: this.selectedCounty } }).then((response) => {
                    this.parishes = response.data.data;
                });
            },
            async getAddresses() {
                this.addresses = [];
                await getAddress(this.form.zipCode).then((response) => {
                    this.addresses = response.data.partes;
                });
            },

            getParishCoords() {
                this.selectedParishLat = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).latitude;
                this.selectedParishLng = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).longitude;
                this.place.coordinates.lat = this.selectedParishLat;
                this.place.coordinates.lng = this.selectedParishLng;
            },

            deleteFacility() {
                this.$store
                    .dispatch('app-facility/deleteFacility', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'FacilityList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #0000ff;
        border-radius: 10px;
    }

    .v-avatar {
        position: relative;
        .file {
            background-color: #0000ff;
            border-radius: 10px;
            padding: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
